<template>
  <v-skeleton-loader type="card-heading, image" v-if="isLoading" />

  <Card title="Informações da fatura" v-else>
    <template v-slot:content>
      <apexchart
        v-if="isVisible"
        height="250"
        :options="options"
        :series="series"
      />
      <div class="d-flex align-center justify-center" v-else>
        <span class="font-weight-light text--secondary">
          Não há dados disponíveis
        </span>
      </div>
    </template>
    <template v-slot:footer>
      <v-row>
        <v-col cols="12" sm="6" class="feed__footer--separator-right">
          <div class="feed__info">
            <h4 class="feed__info--title">{{ movementDay }}</h4>
            <small>Dia limite de movimentações</small>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="feed__info">
            <h4 class="feed__info--title">
              {{ dueDate }}
            </h4>
            <small>Vencimento da fatura</small>
          </div>
        </v-col>
      </v-row>
    </template>
  </Card>
</template>

<script>
import { formatMoney } from '@/helpers/formatting';
import { DashboardService } from '@/services/api/dashboard';

export default {
  components: {
    Card: () => import('./common/card-template')
  },

  props: {
    contractId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    isLoading: false,
    isVisible: true,
    movementDay: 0,
    dueDate: 0,
    series: []
  }),

  computed: {
    options() {
      const isDark = this.$vuetify.theme.isDark;

      return {
        grid: {
          show: true,
          strokeDashArray: 3,
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        markers: {
          size: 3
        },
        colors: isDark ? ['#bcc300'] : ['#5c036f'],
        theme: {
          mode: isDark ? 'dark' : 'light'
        },
        chart: {
          width: '100%',
          height: 200,
          type: 'area',
          toolbar: {
            show: false
          },
          id: 'invoice-chart',
          animations: {
            speed: 200
          }
        },
        fill: {
          type: 'gradient',
          opacity: ['0.1', '0.1']
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false,
          formatter: function (value) {
            return formatMoney(value);
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatMoney(value);
            },
            style: {
              cssClass: 'grey--text',
              fontFamily: 'poppins'
            }
          }
        },
        xaxis: {
          labels: {
            style: {
              cssClass: 'grey--text',
              fontFamily: 'poppins'
            }
          }
        }
      };
    }
  },

  created() {
    this.search();
  },

  methods: {
    formatMoney,

    async search() {
      try {
        this.isLoading = true;
        const dashboardService = new DashboardService(false);
        const { status, data } = await dashboardService.getInvoiceCardData(
          this.contractId
        );

        if (status === 200) {
          this.isVisible = true;
          this.movementDay = data.movementDay;
          this.dueDate = data.dueDate;
          this.series = [
            {
              name: 'Valor',
              data: data.data
            }
          ];
        } else {
          this.isVisible = false;
        }
      } catch (error) {
        this.isVisible = true;
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    }
  },

  watch: {
    contractId() {
      this.search();
    }
  }
};
</script>
